import React, { Component } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import Nav from '../components/nav'
import ExploreIcon from '../components/explore'

class NotFoundPage extends Component {
  render() {
    return (
      <>
        <SEO title="Page Not Found" />
        <Nav>
          <div className="col text-left">
            <Link className="target fill-dark" to="/explore" style={{height:"1.875rem"}}><ExploreIcon /></Link>
          </div>
        </Nav>
        <ReactFullpage
          // fullpage options
          licenseKey={'C1912978-A39F4CE4-9F6AC28B-DFB573DA'}
          sectionsColor={['#e8e7e3']}
          lazyLoading

          render={() => (
            <ReactFullpage.Wrapper>
              <section className="section" key="section0" id="section0">
                <div className="container px-5">
                  <h1 className="cover-heading">Not Found</h1>
                  <p className="lead">You just hit a route that doesn&#39;t exist.</p>
                </div>
              </section>
            </ReactFullpage.Wrapper>
          )}
        />
      </>
    )
  }
}

export default NotFoundPage